<template>
  <div class="text-shadow">
    <div>视场:<span>{{view.fov}}</span></div>
    <div>水平:<span>{{view.hLookAt}}</span></div>
    <div>垂直:<span>{{view.vLookAt}}</span></div>
  </div>
</template>
<script>
  export default {
    components: {
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        view: {}
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getComonent: {
        default: {}
      },
      getFunc: {}
    },
    computed: {
      pano() {
        return this.publicData.pano || {}
      },
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
          setView: {
            target: 'Data',
            name: 'updatePano',
          },
          outLinkView: {
            name: 'renewSence',
            arg: 'hotspot'
          },
          setLinkView: {
            target: 'Data',
            name: 'setLinkView',
          },
          tweenView: {
            target: 'FullPano',
            name: 'tweenView'
          },
        },
        funcs: {
          cancel: this.cancel,
          recoverLinkView: this.recoverLinkView,
          resetView: this.resetView,
          saveLinkView: this.saveLinkView,
          updateView: this.updateView,
        }
      })
      this.updateView()
    },
    destroyed() {
    },
    methods: {
      updateView() {
        var v = this.getFunc({ target: 'FullPano', name: 'getView' })()
        this.view = {
          fov: Number(v.fov).toFixed(1),
          hLookAt: this.math360(Number(v.hlookat)).toFixed(2),
          vLookAt: Number(v.vlookat).toFixed(2),
        }
      },
      recoverLinkView() {
        var view = {
          fov: this.pano.fov,
          hlookat: this.pano.hLookAt,
          vlookat: this.pano.vLookAt,
        }
        this.$emit('tweenView', view)
      },
      resetView() {
        var view = {
          fov: 120,
          hlookat: 0,
          vlookat: 0,
        }
        this.$emit('tweenView', view)
      },
      saveLinkView() {
        this.$emit('setLinkView', { linkFOV: this.view.fov, linkHLookAt: this.view.hLookAt, linkVLookAt: this.view.vLookAt })
        this.cancel()
      },
      cancel() {
        var v = this.publicData.lastView
        this.getFunc('changePublicData')({
          panoQuery: {
            'view.fov': v.fov,
            'view.vlookat': v.vlookat,
            'view.hlookat': v.hlookat,
          },
          linkView:true
        })
        this.$emit('setView')
        this.$emit('outLinkView')
      },
      math360(i) {
        while (i < 0 || i >= 360) {
          i = i > 0 ? i - 360 : i + 360
        }
        return i
      },
    },
  }
</script>
<style scoped>
  .HotspotList {
    position: absolute;
    top: 0;
    left: 0;
    right: 2.5rem;
    overflow: auto;
    white-space: nowrap;
    font-size: 14px;
    margin: 0;
    opacity: 0.75;
    pointer-events: none;
  }

  .hotspotItem {
    display: inline-block;
    margin: 5px;
    /*width: 4.5em;*/
    vertical-align: top;
  }

    .hotspotItem.active {
      margin: 4px;
      border: 1px solid #fff;
      box-shadow: #000 0 0 2px 0;
    }

    .hotspotItem .badge {
      position: absolute;
      right: 2px;
    }

  .PanoHotspotEdit {
    position: absolute;
    right: 0;
    bottom: 0;
    pointer-events: none !important;
    margin: 5px;
    opacity: 0.75;
  }

    .PanoHotspotEdit > * {
      pointer-events: auto;
      margin: 5px 0;
    }

  .rangeBar {
    background-color: #fff5;
    padding: 5px;
    margin: 0px 0 -5px;
    text-align: center;
    border-radius: 0.2rem;
  }
</style>
